.cont{
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding: 1% 1% 1% 1%;
    border: 2px solid gray;
    border-radius: 25px;
    margin-top: 3%;
    text-align: center;
    align-items: center;
}

.radioGroup{
    display: flex;
    padding: 3%;
    justify-content: space-evenly;
}
.headerModal{
    display: flex;
    height: 46px;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
}
.headerLeftModal {
    width: 90%;
    float: center;
    padding-left: 10px;
}
.headerRigthModal {
    padding: 10px;
    margin-right: 21px;
    float: right;
    display: flex;
    width: 10%;
}
.saveButton {
    margin-top: 1rem !important;
    color: #ffffff !important;
    font-weight: lighter !important;
    background: #434343 !important;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
}
.cancelButton {
    margin-top: 1rem !important;
    color: #434343 !important;
    font-weight: lighter !important;
    background: #ffffff !important;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    border: solid !important;
    border-width: 1px !important;
}
.modalButtons{
    flex: 50 50  !important;
    display: flex !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

