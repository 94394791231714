.pay-request-edit {
  .MuiInputBase-formControl {
    height: 27px;
    margin: 10px 0 5px 0px;

    & input {
        font-size: 12px;
        color: #575757!important;
    }
    & svg {
      color: #C32032;
    }
  }
  .inputText {
    width: 405px;
    gap: 34px;
    margin: 10px 0 5px 0px;
  }
  .inputText .MuiFormControl-root .MuiInputBase-input {
    font-size: 12px;
  }

  .MuiFormControl-fullWidth {
    width: 250px;
        
        & label {
            display: none;
        }
        
        & input {
        border-color: rgb(196, 196, 196) !important;
        color: #575757!important;
        font-size: 12px;
        font-family: QuickSand, sans-serif;
    }
  }

  .field-Date {
    width: 245px;
    & label {
        display: none;
    }
    .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
      top: 0;
      border-color: rgba(0, 0, 0, 0.23)!important;
    }
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
      border-radius: 5px!important;
      border-color: rgb(196, 196, 196) !important;


  }



}
