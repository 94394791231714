.container-search{
    //width: 11rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .MuiInputBase-input{
        border: solid 1px $gris;
        border-radius: 10px;
        padding-left: 5px;
        padding-right: 28px;
    }
    .MuiInputBase-input:focus{
        border: solid 2px $grisOscuro;
    }
    .search-icon{
        color: $gris;
        font-size: 1.5rem;
        margin: auto 5px auto -1.8rem;
    }

}