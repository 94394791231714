.conceptos-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  width: 100%;

  & svg {
    fill: #353535;
  }

  .conceptos-header-left {
    display: flex;
    flex-direction: row;
    // gap: 40px;
    align-items: center;
  }
  .conceptos-header-right {
    display: flex;
    flex-direction: row;
    // gap: 40px;
    align-items: center;
  }
}
.conceptos-division {
  height: 2px;
  background-color: #9a9a9a;
  margin: 20px;
  // margin-top: 30px;
  // margin-bottom: 30px;
  // padding: 10px;
}

.inputText .MuiFormControl-root .MuiInputBase-input {
  //text-align: end !important;
}

.css-etu5em-MuiInputBase-root-MuiOutlinedInput-root {
  max-width: 60%;
}
