@import "../../components/CollapsibleTable/index.scss";
@import "../../components/PayRequestProjectionModal/index.scss";

.config-logica .MuiBox-root{
	padding: 0px;
}

.config-logica .toolBarTable .MuiBox-root{
	padding-left: 10px;
}

.containerPagos .containerTab{
	min-height: calc(100vh - 300px) !important;
}

#ProjectionsView .container-table{
	min-height: calc(100vh - 430px) !important;
	padding-top: 0px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
	overflow: unset!important;
	// background-color: slateblue;
}

.header-div{
	// background-color: slateblue;
	display: flex;
	border: 1px solid #a1191e;
	border-radius: 10px;
	margin: 10px;
	

	& > Button{
		margin: 5px;
		margin-left: 15px;
		width: 80px;
		// background-color: rgb(185, 178, 228);
	}
}


.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected:hover, .MuiPickersDay-daySelected{
	background-color: #a1191e !important;
}


.MuiButton-root.save-button {
    color: #ffffff !important;
    background: #434343;
}

.container-table{
	min-height: 500px;
	min-width: 950px;
}


.Modal-styles .MuiDialog-paperWidthSm {
    width: 100% !important;
	max-width: 1182px;
}

#ProjectionsView > div > p > div > div.config-logica > span > div > div > div:nth-child(3){
	max-width: 100% !important;
}

#ProjectionsView > div > p > div > div.config-logica > span > div > div > div:nth-child(3) > div > div.MuiTablePagination-root > div{
	display: inline-flex;
}