// .Modal-styles {
// 	.MuiDialog-paperWidthSm {
// 		min-height: 80%;
// 		height: 80;
// 		max-height:90px ;
// 	}
// }

.Modal-styles .MuiDialog-paperWidthSm {
    width: 850px  !important;
}

// .MuiDialogContent-root{
// 	width: 700px  !important;
// }

.container-table {
    min-height: 450px;
    min-width: 840px !important;
}

// .MuiPaper-root .MuiPaper-elevation .MuiTableContainer-root {
// 	// width: 840px !important;
// 	// min-width: 840px !important;
	
// }