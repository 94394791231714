.cont{
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding: 1% 1% 1% 1%;
    border: 2px solid gray;
    border-radius: 25px;
    margin-top: 3%;
    text-align: center;
    align-items: center;
}

.radioGroup{
    display: flex;
    padding: 3%;
    justify-content: space-evenly;
}
