.Modal-styles {
	.MuiDialog-paperWidthSm {
		width: 600px !important;
		max-width: 95%;
		border-radius: 15px;
		min-height: 450px;
	}

	.MuiDialogTitle-root {
		// background: $primary;
		text-align: center;
		color: #C12336;
		.MuiTypography-h6{
		font-family: Futura-Medium, sans-serif !important;
		font-size: 20px;
		}
	}
	.MuiDialogContent-root{
		padding:0;
		padding-top: 24px;
	}
	.close-modal {
		right: 20px;
		position: absolute;
		cursor: pointer;
	}
	.close-modal:hover,
	.close-modal:focus {
		color: $secundary;
		cursor: pointer;
	}
	form {
		width: 95%;
		margin:0 auto;
		height: 100%;
		justify-content: space-evenly;
		.resumen-pago {
			display: flex;

			.field-Date {
				flex: 1;
			}
			.form-select {
				flex: 2;
			}
		}
		.MuiDialogActions-root{
			width: 100%;
			margin: 0 auto;
			justify-content: right;
			margin-top:100px;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			font-family: Quicksand, sans-serif;
			width: 118px;
			height: 40px;
			border: none;
			background: $grisOscuro;
		}
		.cancel-button {
			margin-top: 1rem;
			color: black;
			font-weight: lighter;
			font-family: Quicksand, sans-serif;
			width: 118px;
			height: 40px;
			background: white;
			border: 1px solid black;
		}
		}
		.Containerform{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.forms{
				height:180px;
				.input{
					flex-basis: 50%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.inputText{
						height:50%;
						justify-content: space-between;
						label {
							flex-basis: 5%;
							color:#484848;
							font-family: Quicksand-Bold, sans-serif;
						}
						.MuiInputBase-root{
							height: 32px;
							width: 210px;
						}

					}
					.form-select{
						padding:0;
						height:50%;
						background-image: none;
						border:none;
						margin: 0 ;
						
						label {
							flex-basis: 2%;
							color:#484848;
							font-family: Quicksand-Bold, sans-serif;
						}
						.MuiInputBase-root{
							margin-right: 72px;
							height: 32px;
							max-width: 210px;
						}
					}
				}
			}
			.form-radio{
				margin-top:60px;
				label{
					color:#484848;
					font-family: Quicksand-Bold, sans-serif;
					font-size: 12px;
				}
			}
		}
	}
	.form-detalle {
		display: flex;
		flex-direction: column;
		min-height: 20rem;
		max-height: 35rem;
		justify-content: space-around;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			background: $grisOscuro;
		}
		.container-table {
			width: 50rem;
			height: 25rem;
		}
		.actions {
			display: flex;
			justify-content: flex-end;
			button {
				font-size: 1.5rem;
			}
		}
	}
	.MuiDialogContent-root .confirmation {
		text-align: center;

		.butonconfirmation {
			display: flex;
			justify-content: space-evenly;
			.cancel-button {
				margin-top: 1rem;
				color: $grisOscuro;
				font-weight: lighter;
				background: $blanco;
			}
			.save-button {
				margin-top: 1rem;
				color: $blanco;
				font-weight: lighter;
				background: $grisOscuro;
			}
		}
	}
}

#modal-solic-pagos {
	background-color: #a1191e;
	display: none; 
  }


  .modal-content{
border-radius: 20px;	
}

.modal600 {
	.MuiDialog-paperWidthSm {
		width: 600px !important;
		max-width: 95%;
		border-radius: 15px;
		min-height: 450px;
	}

	.MuiDialogTitle-root {
		text-align: center;
		color: #C12336;
		.MuiTypography-h6{
		font-family: Futura-Medium, sans-serif !important;
		font-size: 20px;
		}
	}
	.MuiDialogContent-root{
		padding:0;
		padding-top: 24px;
	}
	.close-modal {
		right: 20px;
		position: absolute;
		cursor: pointer;
	}
	.close-modal:hover,
	.close-modal:focus {
		color: $secundary;
		cursor: pointer;
	}
	form {
		width: 95%;
		margin:0 auto;
		height: 100%;
		justify-content: space-evenly;
		.resumen-pago {
			display: flex;

			.field-Date {
				flex: 1;
			}
			.form-select {
				flex: 2;
			}
		}
		.MuiDialogActions-root{
			width: 100%;
			margin: 0 auto;
			justify-content: right;
			margin-top:100px;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			font-family: Quicksand, sans-serif;
			width: 118px;
			height: 40px;
			border: none;
			background: $grisOscuro;
		}
		.cancel-button {
			margin-top: 1rem;
			color: black;
			font-weight: lighter;
			font-family: Quicksand, sans-serif;
			width: 118px;
			height: 40px;
			background: white;
			border: 1px solid black;
		}
		}
		.Containerform{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.forms{
				height:180px;
				.input{
					flex-basis: 50%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.inputText{
						height:50%;
						justify-content: space-between;
						label {
							flex-basis: 5%;
							color:#484848;
							font-family: Quicksand-Bold, sans-serif;
						}
						.MuiInputBase-root{
							height: 32px;
							width: 210px;
						}

					}
					.form-select{
						padding:0;
						height:50%;
						background-image: none;
						border:none;
						margin: 0 ;
						
						label {
							flex-basis: 2%;
							color:#484848;
							font-family: Quicksand-Bold, sans-serif;
						}
						.MuiInputBase-root{
							margin-right: 72px;
							height: 32px;
							max-width: 210px;
						}
					}
				}
			}
			.form-radio{
				margin-top:60px;
				label{
					color:#484848;
					font-family: Quicksand-Bold, sans-serif;
					font-size: 12px;
				}
			}
		}
	}
	.form-detalle {
		display: flex;
		flex-direction: column;
		min-height: 20rem;
		max-height: 35rem;
		justify-content: space-around;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			background: $grisOscuro;
		}
		.container-table {
			width: 50rem;
			height: 25rem;
		}
		.actions {
			display: flex;
			justify-content: flex-end;
			button {
				font-size: 1.5rem;
			}
		}
	}
	.MuiDialogContent-root .confirmation {
		text-align: center;

		.butonconfirmation {
			display: flex;
			justify-content: space-evenly;
			.cancel-button {
				margin-top: 1rem;
				color: $grisOscuro;
				font-weight: lighter;
				background: $blanco;
			}
			.save-button {
				margin-top: 1rem;
				color: $blanco;
				font-weight: lighter;
				background: $grisOscuro;
			}
		}
	}
}