.table-Pago {
  width: 170vh;
//   height: 67vh;
  font-family: Quicksand-SemiBold, sans-serif;
  text-align: center;
  border-color: black;
  thead {
    background-color: $secundary;
    color: $blanco;
    // border: 2px solid  black;
  }
  .header-row {
    background-color: #dc8989;
  }
  //  .childRow{
  //     display: table-row;
  //     vertical-align: inherit;
  //    background-color: $blanco;
  // }
  .subheader {
    height: 9px;
    .title {
      font-size: 10px;
    }
  }
}

.MuiTableRow-root {
  max-height: 30px !important;
}

.MuiTableCell-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
