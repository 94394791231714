// @import '../Contratos/index.scss';

.container-home {
	height: 100vh;
	width: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 99;
	top: 0;

	//Apbarr
	.MuiAppBar-root {
		width: 100%;
		.Mui-selected {
			color: $blanco;
			background: $secundary;
			border-radius: 6%;
			svg {
				color: $blanco;
			}
		}
		.MuiTab-wrapper{
			display: flex;
			flex-direction: row;
			align-items: center;
			svg {
				margin-right: 4px;
			}
		}
	}

	.MuiAppBar-colorPrimary {
		margin-top: 1rem;
		color: $secundary;
		background-color: transparent;
		box-shadow: none;
		align-items: center;
	}
	

	//----Appbar

	.MuiBox-root {
		height: 100%;
		width: 100vh;
		display: flex;
		justify-content: center;
	}
}
