.dialog-Modal {
	.MuiDialog-container .MuiPaper-root{
		border-radius: 15px;
	}

	.MuiDialog-paperWidthSm {
		min-width: 30rem;
		// min-height: 2rem;
		max-width: 60rem;
		// max-height: 50rem;
	}

	
	.close-modal {
		right: 20px;
		position: absolute;
		cursor: pointer;
	}
	.close-modal:hover,
	.close-modal:focus {
		color: $secundary;
		cursor: pointer;
	}
	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		//background: chocolate;
		justify-content: space-evenly;
		.resumen-pago {
			display: flex;

			.field-Date {
				flex: 1;
			}
			.form-select {
				flex: 2;
			}
		}
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			background: $grisOscuro;
		}
	}
	.form-detalle {
		display: flex;
		flex-direction: column;
		min-height: 20rem;
		max-height: 35rem;
		justify-content: space-around;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			background: $grisOscuro;
		}
		.container-table {
			width: 50rem;
			height: 25rem;
		}
		.actions {
			display: flex;
			justify-content: flex-end;
			button {
				font-size: 1.5rem;
			}
		}
	}
	.MuiDialogContent-root .confirmation {
		text-align: center;

		.butonconfirmation {
			display: flex;
			justify-content: space-evenly;
			.cancel-button {
				margin-top: 1rem;
				color: $grisOscuro;
				font-weight: lighter;
				background: $blanco;
			}
			.save-button {
				margin-top: 1rem;
				color: $blanco;
				font-weight: lighter;
				background: $grisOscuro;
			}
		}
	}
}

#modal-solic-pagos {
	background-color: #a1191e;
	display: none; 
}

.dialog-Modal .MuiDialogTitle-root {
	text-align: center;
	color: $secundary;
	h2{
		font-family: Futura-Medium, sans-serif!important;
		font-size: 20px;
		justify-content: space-around;
		width: 100%;
		display: flex;
		#title{
			width: 90%;
		}
	}
}