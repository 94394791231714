.detalles-container {
    // display: flex;
    // justify-content: flex-start;
    // text-align: left;
    height: 100%;
    width: 100%;

    .MuiTypography-root{
		height: auto;
	}

    // background-color: slateblue;

    .division {
        height: 2px;
        background-color: #9A9A9A;
        margin: 20px;
        // margin-top: 30px;
        // margin-bottom: 30px;
        // padding: 10px;
      }

    .button-detail-next {
        background-color: #FFFF;
        border: 1px solid #353535;
        color: #353535;
        height: 40px;
        width: 118px;

        & label {
            color: #9A9A9A;
            text-transform: uppercase;
            // font-family: 'QuickSand';
            font: 14px/18px QuickSand, sans-serif;
        }
    }

}