
.config-Modal div.MuiDialog-container.MuiDialog-scrollBody > div
{
	min-width: 75% !important;
	max-width: 1400px !important;
}

.config-Modal  .MuiDialog-paperScrollBody {
	// display: block !important;
}

.config-Modal div.MuiDialog-container.MuiDialog-scrollBody > div > div.MuiDialogContent-root{
	// min-width: 900px !important;
}

.config-Modal .container-table{
	align-items: center;
	.MuiDataGrid-root{
		max-width: 1400px;
	}
} 