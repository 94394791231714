.button-detail-next {
  background-color: #353535;
  border: 1px solid #353535;
  color: #ffffff;
  height: 40px;
  width: 118px;

  & label {
    color: #ffffff;
    text-transform: uppercase;
    // font-family: 'QuickSand';
    font: 14px/18px QuickSand, sans-serif;
    cursor: pointer;
  }
}


.MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-menuIcon, .MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-menuIcon {
  display: none;
}
