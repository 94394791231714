.ejecuciones-container {
  .header-ejecucion {
    display: flex;
    flex-direction: row;
    padding: 15px;

    .label-header-ejecuciones {
      flex: auto !important;
      margin: 0px 0px 5px 0px !important;
      color: #484848;
      font: normal normal bold 12px Quicksand-Bold, sans-serif;
    }
  }
  .header-left-ejecucion {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
  }
  .header-right-ejecucion {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    margin: auto;
    margin-right: 0px !important;
  }
  .add-button {
    color: $grisOscuro;
    font-size: 1.8rem;
    margin-right: 5px;
    display: none;

    .MuiSvgIcon-root {
      color: $grisOscuro;
    }
  }
  /* Year input */
  .form-select-year {
    border: 1px solid #707070;
    border-radius: 20px;
    height: 31px;
    width: 83px;
    font-size: 12px;
    text-align: center;
  }
  .form-select-year:focus {
    padding: 8px;
    outline-color: $primary;
  }
  .select-drop {
    align-items: center;
    display: flex;
    svg {
      color: #434343;
    }
    label {
      flex: 1;
      text-align: start;
      color: red;
    }
    .select {
      border-radius: 20px;
      display: flex;
      height: 30px;
      width: 100%;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #707070;
    }

    #months {
      width: 100px;
    }
    #contracts {
      width: 180px;
    }
  }
  #boton-motor {
    background-color: #434343;
    border-radius: 0 !important;
    color: #ffff;
    cursor: pointer;
    font-size: 10px;
    height: 31px;
    width: 139px;
    label {
      cursor: pointer;
    }
  }

  .ejecucion-manual {
    align-items: center;
    background-color: #434343;
    color: #ffff;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    height: 20px;
    width: 139px;
  }

  .checbox-ejecuciones {
    display: flex !important;
    margin: 0px 0px 4px 0px !important;
    .MuiSvgIcon-root {
      color: #939393 !important;
      width: 18px !important;
      height: 18px !important;
    }
  }

  .MuiDataGrid-root .MuiDataGrid-main {
    background-color: white;
    box-shadow: 0px 2px 2px #00000029;

    .MuiDataGrid-row:nth-child(odd) {
      background-color: #f9fafc !important;
    }
    .MuiDataGrid-row.Mui-selected {
      background-color: #d3d6dd !important;
    }
    .MuiDataGrid-row.Mui-selected:nth-child(odd) {
      background-color: #d3d6dd !important;
    }
  }
}
.dialog-Modal-ejecuciones .MuiDialog-paperWidthSm {
  min-width: 63%;
  max-width: 65%;
  .MuiDialog-paperWidthSm {
    min-width: 30rem;
    max-width: 60rem;
  }

  .MuiDialogTitle-root {
    font-family: Futura-Medium, sans-serif;
    font-size: 20px;
    color: #c12336;
    text-align: center;
  }
  .close-modal {
    right: 20px;
    position: absolute;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
  .close-modal:hover,
  .close-modal:focus {
    color: $secundary;
    cursor: pointer;
  }
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    .resumen-pago {
      display: flex;

      .field-Date {
        flex: 1;
      }
      .form-select {
        flex: 2;
      }
    }
    .save-button {
      margin-top: 1rem;
      color: $blanco;
      font-weight: lighter;
      background: $grisOscuro;
    }
  }
  .form-detalle {
    display: flex;
    flex-direction: column;
    min-height: 20rem;
    max-height: 35rem;
    justify-content: space-around;
    .save-button {
      margin-top: 1rem;
      color: $blanco;
      font-weight: lighter;
      background: $grisOscuro;
    }
    .container-table {
      width: 50rem;
      height: 25rem;
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      button {
        font-size: 1.5rem;
      }
    }
  }
  .MuiDialogContent-root .confirmation {
    text-align: center;

    .butonconfirmation {
      display: flex;
      justify-content: space-evenly;
      .cancel-button {
        margin-top: 1rem;
        color: $grisOscuro;
        font-weight: lighter;
        background: $blanco;
      }
      .save-button {
        margin-top: 1rem;
        color: $blanco;
        font-weight: lighter;
        background: $grisOscuro;
      }
    }
  }
}

.table-events-calculo {
  border-radius: 5px;
  box-shadow: 0px 2px 2px #00000029;
  font: normal normal normal 12px/15px;
  font-family: QuickSand, sans-serif;
  gap: 0;
  width: 100%;

  & tr {
    align-items: center;
    display: grid;
    grid-template-columns: 180px 400px 180px 250px 140px;
    width: 100%;
    margin-left: 5px;
  }

  & > thead {
    display: grid;
    border-radius: 5px 5px 0px 0px;
    background-color: #434343 !important;
    color: #ffff;
    height: 45px;
  }
  & > thead > tr > th {
    margin-left: 5px;
  }
  & > tbody > tr > td {
    align-items: center !important;
    background-color: white;
    color: #484848;
    display: grid;
    font-size: 12px;
    font-weight: 500;
    gap: 2px;
    height: 40px;
  }

  & > tbody > tr > td:last-child {
    text-align: right;
  }

  & > tbody > tr:nth-child(odd) > td {
    background-color: #f9fafc;
  }
}
