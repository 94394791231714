$primary: #c32032;
$blanco: #ffffff;
$blancoNitido: #f2f2f2;

.carousel {
  position: absolute;
  top: 0;
  min-width: 40%;
}

.carousel-inner {
  max-height: 100vh;
  min-height: 100vh !important;
  max-width: 100%;
}

.carousel-indicators li {
  background: $primary;
  width: 9px;
  border-top: 0px solid transparent;
  border-bottom: 9px solid transparent;
  border-radius: 5px;
  height: 0px;
}
// .carousel-indicators li
.container-carousel {
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 0vh;
  max-height: 100vh;
}

.container-login-form {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 60%;
  height: 100vh;
  min-height: 100vh;
}

.carousel-item img {
  max-height: 100vh;
  min-height: 100vh !important;
  // position: fixed;
  max-width: 100%;
}
.container-login {
  flex-direction: inherit;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  .carousel-caption,
  .carousel-indicators {
    color: #c32032;
  }

  .login {
    margin: auto;
    padding: 60px 30px 50px;
    background: #fff;
    // box-shadow: 0px 3px 6px #00000029;
    // border-radius: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-herdez {
      width: 180px;
    }
    .bienvenido {
      text-align: left;
      font-family: Futura-medium, sans-serif;
      font-size: 20px;
      margin: 30px 0;
      color: "#434343";
      opacity: 1;
    }
    .google-button-login {
      width: 254px;
      height: 40px;
      background-color: $primary !important;
      box-shadow: none !important;
      border: none !important;
      span {
        padding: 0 !important;
      }
      .container-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        img {
          border-top: 2px solid #9a9a9a;
          border-left: 2px solid #9a9a9a;
          border-bottom: 2px solid #9a9a9a;
          border-right: none;
          padding: 7px 9px;
          //   width: 45px;
          height: 36px;
          background: $blanco;
        }
        div {
          margin: auto;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          font-weight: lighter;
          padding: 0px 7px;
          color: $blanco;
        }
      }
    }
  }
}
