.container-table {
	width: 100%;
	display: flex;
	background-color: transparerent;
	align-items: flex-start;//center
	flex-direction: column;
	height: 300px;
	padding: 10px;
	.MuiDataGrid-root {
		width: 100%;
		.MuiDataGrid-columnHeaderTitle {
			font-family: Quicksand-Bold, sans-serif !important;
			font-size: 12px;
			letter-spacing:0em;
		}
		.MuiDataGrid-cell {
			font-family: Quicksand, sans-serif !important ;
			font-size: 12px;
			letter-spacing:0em;
		}
		.MuiDataGrid-columnHeaderWrapper {
			background: $grisOscuro;
			color: $blanco;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			.MuiSvgIcon-root {
				color: $blanco;
			}
		}
		.MuiDataGrid-footerContainer {
			display: block;
		}
	}
	svg{
		color: $negro;
	}
}

.container-table-cedula1 {
	width: 1100px;
	display: flex;
	align-items: flex-start;//center
	flex-direction: column;
	height: 130px;
	padding: 10px;
	.MuiDataGrid-root {
		width: 100%;
		.MuiDataGrid-columnHeaderTitle {
			font-family: Quicksand-Bold, sans-serif !important;
			font-size: 12px;
			letter-spacing:0em;
		}
		.MuiDataGrid-cell {
			font-family: Quicksand, sans-serif !important ;
			font-size: 12px;
			letter-spacing:0em;
		}
		.MuiDataGrid-columnHeaderWrapper {
			background: $grisOscuro;
			color: $blanco;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			.MuiSvgIcon-root {
				color: $blanco;
			}
		}
		.MuiDataGrid-footerContainer {
			display: none;
		}
	}
	svg{
		color: $negro;
	}
}

.container-table-cedula2 {
	width: 100%;
	display: flex;
	align-items: flex-start;//center
	flex-direction: column;
	height: 310px;
	padding: 10px;
	.MuiDataGrid-root {
		width: 100%;
		.MuiDataGrid-columnHeaderTitle {
			font-family: Quicksand-Bold, sans-serif !important;
			font-size: 12px;
			letter-spacing:0em;
		}
		.MuiDataGrid-cell {
			font-family: Quicksand, sans-serif !important ;
			font-size: 12px;
			letter-spacing:0em;
		}
		.MuiDataGrid-columnHeaderWrapper {
			background: $grisOscuro;
			color: $blanco;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			.MuiSvgIcon-root {
				color: $blanco;
			}
		}
		.MuiDataGrid-footerContainer {
			display: none;
		}
	}
	svg{
		color: $negro;
	}
}

.MuiCheckbox-colorPrimary.Mui-checked {
	color: $blanco;
}

.MuiFormLabel-root {
	color: $gris !important;
}
.MuiInput-underline:after {
	border-bottom: none !important;
}
.MuiSwitch-thumb {
	color: $primary !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: $gris !important;
}
.MuiButton-textPrimary {
	color: $negro !important;
}


.detailTable{
	background: pink;
	width: 200vh;
	.MuiPaper-root{
		width: 170vh;
	}
}
