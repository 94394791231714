.Container {
    width:100%;
    height:100vh;
    display:flex;
    background-color: white ;
}
.Section {
    width: 100%;
    height:100%;
    display:flex;
    flex-direction: column;
}
.header-container {
    width: 95%;
    margin: 0 auto;
    padding:0;
    min-height:80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}