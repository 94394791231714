.container{
    margin-left: 4rem;
    width: 100%; 
    display: flex; 
    justify-content: center;
    align-items: cente ;
    flex-direction: column;
}
.cont{
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding: 1% 1% 1% 1%;
    border: 2px solid #166f57;
    border-radius: 25px;
    margin-top: 3%;
    text-align: center;
    align-items: center;
}
.entrado{
    width: 100%;
    text-align: center;
    font-size: 2vw;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #166f57;
    font-family: Quicksand-Bold, sans-serif
}
.icons{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.form{
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding: 1% 1% 1% 1%;
    border: 2px solid #166f57;
    border-radius: 25px;
    margin-top: 3%;
    text-align: center;
    align-items: center;
   
}
.classNative{
    width: 7vw;
    background-color:#166f57;
    color:#ffffff;
    font-family: Quicksand-Bold, sans-serif
}
.tableHeaderStyle{
    background-color:#166f57;
    color:#ffffff;
    font-family: Quicksand-Bold, sans-serif
}
.demoRowEven{
background: #166f57;

}
.demoRowOdd{
    background: #009b503d;
    
}
.MuiTypography-body1{
    margin-left: 4rem;
}
