
.container-paginator{
    height:100%;
    margin:0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.link-header{
    color:#484848;
    text-decoration: none;
    font-size: 12px;
    font-family: Quicksand, sans-serif;
}
.link-header:hover{
    color:red;
    font-size: 12px;
    font-family: Quicksand, sans-serif;
}
