.cont{
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding: 1% 1% 1% 1%;

    margin-top: 3%;
    text-align: center;
    align-items: center;
}
.saveButton {
    margin-top: 1rem !important;
    color: #ffffff !important;
    font-weight: lighter !important;
    background: #434343 !important;
}
.modalButtons{
    flex: 0 0 auto !important;
    display: flex !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

.entrado{
    width: 100%;
    text-align: center;
    font-size: 2.5vw;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #166f57;
}
.contFrom{
    width: 491px;
    justify-content: flex-start;
    display: flex;
    margin-top: 3%;
    text-align: center;
    align-items: center;
    font-family: Quicksand-Bold, sans-serif;
    font-size: 12px;
}
.campo{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.saveCont{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.modal{
    max-width: 600px !important;
    max-height: 400px !important;
}
.gridContainer {
    display:grid;
	grid-template-columns: 568px;
}
.label {
    width:70px; 
    text-align: initial;
    align-self: flex-start;
    margin-top: 8px;
}
.headerModal{
    display: flex;
    height: 46px;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
}
.headerLeftModal {
    width: 90%;
    float: left;
    padding-left: 10px;
}
.headerRigthModal {
    padding: 10px;
    margin-right: 21px;
    float: right;
    display: flex;
    width: 10%;
}
.campoButton {
    cursor: pointer;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.26);
    border-width: 1px;
    border-radius: 0.3rem;
    height: 38px;
    width: 38px;
    align-self: flex-start;
    padding-top: 10px;
    margin-top: -5px;
}