
.containerTab {
	min-height: calc(100vh - 180px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 99;
	top: 0;
	background-color: white;
	border-radius: 29px;
	//Apbarr
	.MuiAppBar-root {
		width: 95%;
		.MuiTab-root{
			max-width: 300px;
		}
		.Mui-selected {
			color: $blanco;
			svg {
				color: $blanco;
			}
		}
		.MuiTab-wrapper{
			display: flex;
			flex-direction: row;
			align-items: center;
			svg {
				margin-right: 4px;
				margin-bottom: 0;
			}
		}
		.MuiTabs-root {
			display: flex;
			overflow: hidden;
			min-height: 48px;
			-webkit-overflow-scrolling: touch;
			.MuiTabs-scroller {
				flex: 1 1 auto;
				display: inline-block;
				position: relative;
				white-space: nowrap;
				.MuiTabs-flexContainer{
					height: 48px;
					gap: 30px;
				}
				button.MuiButtonBase-root{
					
					svg {
						margin-right: 4px;
					}
				}
			}			
		}
	}

	.MuiAppBar-colorPrimary {
		margin-top: 1rem;
		color: #8A8A8D;
		background-color: transparent;
		box-shadow: none;
		align-items: baseline;
		margin-left: 30px;
	}
	

	//----Appbar

	.MuiBox-root {
		display: flex;
		width: 100%;
		height: 100%;
	}
	.MuiSvgIcon-root {
		color: gray ;
	}

	.MuiTypography-root{
		height: 100%;
	}
	.MuiTypography-body1{
		width: 100%; // Se movió el width a esta clase para no afectar el paginado
	}

	.MuiFormControlLabel-label{
		text-align: left;
		font: normal normal bold 12px/15px Quicksand, sans-serif;
		letter-spacing: 0px;
		color: #484848;
		opacity: 1;
		width: auto;
	}

	.MuiGrid-item{
		padding: 0px 12px !important;
	}
	div[role="tabpanel"]{
		width: 100%;
		border-radius: 29px;
		background-color: #F8F8F8;
		height: 100%;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	
}


.MuiTabs-indicator{
	display: none;
}