.input-container {
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
	.input-labelText {
		margin-right: 1rem;
	}
	input {
		width: 70%;
		border: 1px solid #707070;
		border-radius: 5px;
		opacity: 1;
	}
}

.form-radio {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;

	label {
		text-align: start;
		flex: 1;
		//background: hotpink;
	}
	label[disabled] {
		color: $gris;
		.MuiRadio-colorSecondary.Mui-disabled {
			opacity: 0.4;
		}
	}

	.radio-options {
		flex: 2;
		display: flex;
	}
}

.form-select {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;
	padding: 0px;
	border: 0px;
	label {
		text-align: start;
		flex: 1;
		font-family: Quicksand-Bold, sans-serif;
		font-size: 12px;
		color: $negro;
	}
	.select {
		flex: 2;
		width: 100%;
		font-family: Quicksand, sans-serif;
		font-size: 12px;
	}
	.MuiSelect-iconOutlined{
		display: none;
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: $borderInput;
		height: 32px;
		top: 10px;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: $primary;
	}
}

.inputText {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;
	label {
		text-align: start;
		flex: 1;
		font-family: Quicksand-Bold, sans-serif;
		font-size: 12px;
		color: $negro;
	}
	.MuiFormControl-root {
		flex: 2;
		&:hover {
			border-color: $primary;
		}
		.MuiInputBase-input{
			font-family: Quicksand, sans-serif;
			font-size: 12px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: $borderInput;
			height: 32px;
			top: 10px;
		}
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: $primary;
		}
		.MuiAutocomplete-input {
			font: normal normal normal 12px Futura-Medium !important;
			color: #8B8B8B;
			padding: 0px 4px 7.5px 6px;
		}
	}
}

.field-Date{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;
	label {
		text-align: start;
		flex: 1;
		font-family: Quicksand-Bold, sans-serif;
		font-size: 12px;
		color: $negro;
	}
	.MuiFormControl-root {
		flex: 2;
		&:hover {
			border-color: $primary;
		}
		.MuiInputBase-input{
			font-family: Quicksand, sans-serif;
			font-size: 12px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: $borderInput !important;
			height: 32px;
			top: 10px;
		}
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: $primary;
		}
	}
}

.inputTextSearch {
	 display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;

	label {
		text-align: start;
		flex: 1;
	}
	.MuiFormControl-root {
		flex: 2;
		&:hover {
			border-color: $primary;
		}
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: $primary;
		}
	}
}

.inputTextArea {
	width: 100%;
	height: 114px;
	display: flex;
	align-items: baseline;
	justify-content: space-around;
	margin: 5px;
	label {
		text-align: start;
		align-self: flex-start;
		flex: 1;
		font-family: Quicksand-Bold, sans-serif;
		font-size: 12px;
		color: $negro;
	}
	textarea {
		width: 100%;
		height: 114px !important;		
		font-family: Quicksand, sans-serif;
		font-size: 12px;
		color: $negro;
		background-color: #FFFFFF;
		border-color: $borderInput;
		border-radius: 5px;
		padding: 5px 14px;
	}
	.textAreaContainer{
		flex: 2;
	}
}

.noBorderInputText {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;
	label {
		text-align: start;
		flex: 1;
		font-family: Quicksand-Bold, sans-serif;
		font-size: 12px;
		color: $negro;
	}
	.MuiFormControl-root {
		flex: 2;
		.MuiInputBase-input{
			font-family: Quicksand, sans-serif;
			font-size: 12px;
			padding-left: 0px;
		}
		.MuiOutlinedInput-notchedOutline {
			border: 0px;	
			height: 32px;
			top: 10px;
		}
		.Mui-disabled {
			color: #575757;
		}
	}
}

.inputTextNumber{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px;
	label {
		text-align: start;
		flex: 1;
		font-family: Quicksand-Bold, sans-serif;
		font-size: 12px;
		color: $negro;
	}
	.MuiFormControl-root {
		flex: 2;
		&:hover {
			border-color: $primary;
		}
		.MuiInputBase-input{
			font-family: Quicksand, sans-serif;
			font-size: 12px;
			text-align: right;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: $borderInput;
			height: 32px;
			top: 10px;
		}
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: $primary;
		}
		.MuiInputBase-formControl{
			width: 75px;
		}
	}
}