$primary: #C32032;
$secundary: #a1191e;
$primayNitido: rgba(115, 18, 23, 0.8);
$blanco: #ffffff;
$blancoNitido: #f2f2f2;
$negro: #484848;
$gris: #8a8a8d;
$grisOscuro: #434343;
$rojo: #c10230;
$borderInput: #707070;

//components
@import "./componets/Search/index.scss";
@import "./componets/TableData/index.scss";
@import "./componets//Modal/index.scss";
@import "./componets/ErrorMessage/index.scss";
@import "./componets/Input/index.scss";
@import "./componets/DetailTable/index.scss";
@import "./componets/Custom/Navigation/Tabs/TabContainer/Tab/tab.scss";
@import "./componets/NewModal/index.scss";
//pages
@import "./pages/Login/index.scss";
@import "./pages/Administracion/index.scss";
@import "./pages/Contratos/pages/index.scss";
@import "./pages/PayRequest/pages/Index/index.scss";
@import "./pages/Administracion/views/logicConfiguration/index.scss";
@import "./containers/ConfigSalida/index.scss";
@import "./containers/ConfigCalculo/index.scss";
@import "./pages/Home/index.scss";
@import "./containers/Ejecuciones/index.scss";
@import "./pages/PayRequest/components/PayRequestModalCFDI/index.scss";
@import "./pages/PayRequest/components/PayRequestModalEdit/index.scss";
@import "./pages/Contratos/components/ModalesArrendadores/index.scss";
@import "./pages/Contratos/components/Conceptos/index.scss";
//----------------
body{
	background-color: #FFFFFF;
}
.big-container {
	height: 100%;
}
.big-container2 {
	z-index: 2;
	height: 100vh;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
}

//remove last column separator in dataGrid
.MuiDataGrid-columnHeaderWrapper .MuiDataGrid-columnHeader--sortable:last-child .MuiDataGrid-columnSeparator{
	display: none !important;
	opacity: 0 !important
}

.logout {
	z-index: 999;
	display: flex;
	align-items: center;
	.nameUser {
		font-family: Futura Bold, Quicksand, sans-serif;
		font-weight: bolder;
		font-size: 12px;
		color: #484848;
		margin-right: 10px;
	}
}
//----------------
// appbar

//mensaje al editar/crear
.MuiSnackbar-root {
	z-index: 100;
	svg {
		color: $blanco;
	}
}

#root {
	height: 100%;
}
label {
	display: inline-block;
	margin-bottom: 0rem !important;
}
.form-control {
	border: 1px solid $secundary !important;
	border-radius: 4.25rem !important;

	padding: 4% 17% 4% 4% !important
    ;
}
.MuiFormHelperText-root {
	color: red !important;
}
.search-label {
	width: 70% !important;
}
.modal-header {
	background-color: $primary !important;
	font-size: 1.4vw;
	color: #ffffff !important;
	text-align: center;
}
.selection-cell-header {
	background-color: $primary !important;
	font-size: 1.4vw;
}
.react-bootstrap-table {
	font-family: Quicksand-SemiBold, sans-serif !important;
}
@font-face {
	font-family: "Quicksand-SemiBold";
	src: url("./assets/fonts/Quicksand-SemiBold.ttf");
}
@font-face {
	font-family: "Quicksand-Bold";
	src: url("./assets/fonts/Quicksand-Bold.ttf");
}
@font-face {
	font-family: "Futura-Medium";
	src: url("./assets/fonts/Futura-Medium.otf");
}

.referencia-modal {
	.MuiDialog-paperWidthSm {
		min-width: 30rem;
		max-width: 95vw !important;
	}

	.MuiDialogTitle-root {
		text-align: center;
		color: $secundary;
	}
	.close-modal {
		right: 20px;
		position: absolute;
	}
	.close-modal:hover,
	.close-modal:focus {
		color: $secundary;
	}
	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-evenly;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			background: $grisOscuro;
		}
	}
	.form-detalle {
		display: flex;
		flex-direction: column;
		min-height: 20rem;
		max-height: 35rem;
		justify-content: space-around;
		.save-button {
			margin-top: 1rem;
			color: $blanco;
			font-weight: lighter;
			background: $grisOscuro;
		}
		.container-table {
			width: 50rem;
			height: 25rem;
		}
		.actions{
			display: flex;
			justify-content: flex-end;
			button{
				font-size: 1.5rem;
			}
		}
	}
}

thead {
	background-color: #434343 !important;
}

.makeStyles-root-25 .MuiSelect-select {
	height: 3px;
}

.burguer-logout {
	width: 22vh;
}

.toolBarTable .MuiInputBase-root.MuiOutlinedInput-root{
	height: 31px;
	min-height: 31px;
	max-height: 31px;
}

.containerTab .MuiSvgIcon-root {
	color: $gris;
}

.config-logica {
	display:block ;
}

.MuiTypography-h5{
	color: $primary;
	letter-spacing: 0px;
	font-family: Futura, sans-serif;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
	justify-content: center;
}
.MuiDataGrid-root .MuiDataGrid-menuIcon{
	display: none;
}
.MuiDataGrid-iconButtonContainer{
	display: none;
}
