
.tableDatail{
    margin-top: 5px; 
    .MuiTableCell-alignRight{
        background-color: $secundary;
        color: $blanco;
    }
    .detailtotal{
        background-color: $secundary;
        color: $blanco;
    }
}