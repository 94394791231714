
.MuiCheckbox-colorPrimary.Mui-checked {
	color: $blanco;
}

.MuiFormLabel-root {
	color: $gris !important;
}
.MuiInput-underline:after {
	border-bottom: none !important;
}
.MuiSwitch-thumb {
	color: $primary !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: $gris !important;
}
.MuiButton-textPrimary {
	color: $negro !important;
}


.detailTable{
	background: pink;
	width: 200vh;
	.MuiPaper-root{
		width: 170vh;
	}
}

.black{
	fill:black;
	path{
		fill:black !important;
	}
}
.config-salida {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.container-title{
		width:98%;
		padding: 30px 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title{
			margin:0;
			color:#C32032;
			font-family: Futura-Medium, sans-serif;
			font-size: 20px;
		}
		.actions {
			.add-button {
				font-size: 0.7rem;
				margin-right: 5px;
				svg {
					size: 1.8;
					color: $grisOscuro;
				}
			}
			.solpago-button {
				font-size: 0.7rem;
				color: $blanco;
				background: $grisOscuro;
				svg {
					margin-left: 2px;
					color: $blanco;
				}
			}
			.MuiButton-root.Mui-disabled svg{
				color: $gris;
			}
		}
	}
}
