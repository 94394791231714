.header-pagos {
  display: flex;
  flex-direction: row;
  font: normal normal medium 20px/27px;
  justify-content: space-between;
  letter-spacing: 0px;
  padding: 10px 5px 0px 5px;
  opacity: 1;
  text-align: left;
  width: 100%;

  & h5 {
    font-family: Quicksand-SemiBold, sans-serif;
    color: #c12336;
  }

  & h6 {
    font-family: Quicksand, sans-serif;
  }

  .header-left {
    padding: 10px;
    height: 100%;

    .inputText {
      // background-color: #a1191e;
      display: block;
      height: 40px;
      margin: 0;
	  padding: 10px;
      width: 100%;

      & label {
        display: none;
      }
	  & input {
		//   padding: 0;
		color: #8B8B8B;
		font-family: Helvetica, sans-serif;
		font-size: 12px;
		padding: 10px 12px 20px;

	  }
	  & fieldset {
		  border-color: #353535;
	  }
    }
  }
  .header-right {
    padding: 10px;
    // margin-right: 20%;

  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected:hover,
.MuiPickersDay-daySelected {
  background-color: #a1191e !important;
}

.div-detalles-padre {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.div-col-1 {
  flex-direction: column;
  gap: 20px;
  display: flex;
  margin-right: 50px;

  & > .inputText {
    gap: 20px;
  }
}
.div-col-2 {
  flex-direction: column;
  gap: 20px;
  display: flex;
  margin-left: 50px;

  & > .inputText {
    gap: 20px;
  }
}

.div-modal-content {
  display: flex;
  justify-content: space-between;
}

.division {
  height: 1px;
  background-color: #a1191e;
  // margin: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
}
